// export { default as Alert } from 'bootstrap/js/src/alert';
// export { default as Button } from 'bootstrap/js/src/button';
// export { default as Carousel } from 'bootstrap/js/src/carousel';
// export { default as Collapse } from 'bootstrap/js/src/collapse';
// export { default as Dropdown } from 'bootstrap/js/src/dropdown';
// export { default as Modal } from 'bootstrap/js/src/modal';
// export { default as Offcanvas } from 'bootstrap/js/src/offcanvas';
// export { default as Popover } from 'bootstrap/js/src/popover';
// export { default as ScrollSpy } from 'bootstrap/js/src/scrollspy';
// export { default as Tab } from 'bootstrap/js/src/tab';
// export { default as Toast } from 'bootstrap/js/src/toast';
// export { default as Tooltip } from 'bootstrap/js/src/tooltip';

// import Alert from 'bootstrap/js/dist/alert'
import Button from 'bootstrap/js/dist/button'
// import Carousel from 'bootstrap/js/dist/carousel'
import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/dist/dropdown'
// import Modal from 'bootstrap/js/dist/modal'
import Offcanvas from 'bootstrap/js/dist/offcanvas'
// import Popover from 'bootstrap/js/dist/popover'
// import Scrollspy from 'bootstrap/js/dist/scrollspy'
// import Tab from 'bootstrap/js/dist/tab'
// import Toast from 'bootstrap/js/dist/toast'
// import Tooltip from 'bootstrap/js/dist/tooltip'
//
export {
// 	Alert,
	Button,
// 	Carousel,
	Collapse,
	Dropdown,
// 	Modal,
	Offcanvas,
// 	Popover,
// 	Scrollspy,
// 	Tab,
// 	Toast,
// 	Tooltip
}
